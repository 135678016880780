<template>
  <tr style="padding: 0px;">
    <td style="padding: 0px;text-align: center;"> {{index + 1}} </td>

    <td style="padding: 0px;">
<!--      <ItemBox v-if="detail" :myitems="items" :myitem="detail.item"  v-on:itemchanged="onItemChanged" ></ItemBox>-->
      <select class="form-control select-search" v-if="detail" v-model="detail.item.id" @change="rowValueEqualizer(index, 1)">
        <option v-for="item in items" v-bind:value="item.id">
          {{ item.name }}
        </option>
      </select>
    </td>

    <td style="padding: 0px; width: 30px;" >
      <select class="form-control text-center" v-if="detail" v-model="detail.gst_rate" @change="rowValueEqualizer(index, 2)" disabled>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="5">5</option>
        <option value="12">12</option>
        <option value="18">18</option>
        <option value="28">28</option>
      </select>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.rate" @change="rowValueEqualizer(index, 3)" />
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.qty" @change="rowValueEqualizer(index, 4)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.discount" @change="rowValueEqualizer(index, 5)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.taxable_amt" @change="rowValueEqualizer(index, 6)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.igst_amt" :disabled="isIntegrated"  @change="rowValueEqualizer(index, 7)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.cgst_amt" :disabled="isState" @change="rowValueEqualizer(index, 8)"/>
    </td>
    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.sgst_amt" :disabled="isState" @change="rowValueEqualizer(index, 9)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.net_amt" readonly />
    </td>

    <td style="padding: 0px;text-align: right;">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>
  </tr>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import ItemBox from '@/components/core/ItemBox.vue'
  import numeral from 'numeral'
  export default {
    name: 'InvoiceDetailRow',
    store,
    components: {
      ItemBox,
    },
    props: {
      myrow: {
        type: Object,
        default: () => JSON.parse('{"idx":0,"id":0,"order_id":1,"workorder_id":1,"hsn":"","item":{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"rate":0,"qty":0,"qty_txt":"","weight":0,"discount":0,"taxable_amt":0,"round_off":0,"gst_rate":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"cess_amt":0,"net_amt":0}')
      },
      myindex: {
        type: Number,
        default: () => 0
      },
      items:{
        type: Array,
        default: () => new Array()
      },
      vchtype:{
        default: () => 0
      },
      state:{
        type: Number,
        default: () => 0
      }
    },
    data (){
      return {
        detail: JSON.parse('{"idx":0,"id":0,"order_id":1,"workorder_id":1,"hsn":"","item":{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"rate":0,"qty":0,"qty_txt":"","weight":0,"discount":0,"taxable_amt":0,"round_off":0,"gst_rate":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"cess_amt":0,"net_amt":0}'),
        index: 0,
        isIntegrated: true,
        isState: false
      }
    },
    beforeMount () {
      this.detail = this.myrow;
      this.index = this.myindex;

      this.detail.taxable_amt = this.detail.taxable_amt.toFixed(2);
      this.detail.rate = this.detail.rate.toFixed(2);
      this.detail.igst_amt = this.detail.igst_amt.toFixed(2);
      this.detail.cgst_amt = this.detail.cgst_amt.toFixed(2);
      this.detail.sgst_amt = this.detail.sgst_amt.toFixed(2);
      this.detail.net_amt = this.detail.net_amt.toFixed(2);
    },
    mounted(){
      this.checkIGST();
    },
    methods:{
      onItemChanged(item){
        const self = this;
        self.$data.detail.item = item;
        self.rowValueEqualizer(self.$data.detail.idx , 1);
      },
      get_item_from_id(id) {
        let item = new Item();
        item.id = 0;
        return item;
      },
      removeRow(idx){
        this.$emit('onRemoveRow', idx );
      },
      calculate_gst(detail){
        var taxrate =  detail.gst_rate;
        if  (this.$props.vchtype == 301 || this.$props.vchtype == 307 || this.$props.vchtype == 308) {
          taxrate = detail.gst_rate;
        }

        var taxable_amt = (detail.qty * detail.rate) - detail.discount;


        var tax_value = Math.round(taxable_amt * (taxrate / 100) * 100);

        if (tax_value % 2 !== 0) {
          tax_value++;
        }
        tax_value = tax_value / 100;

        detail.tax_amt = tax_value;

        if (this.$props.state == 0 || (this.$props.state == store.state.user.state_cd)) {
          detail.igst_amt = 0;
          detail.sgst_amt = tax_value / 2;
          detail.cgst_amt = tax_value / 2;
        } else {
          detail.igst_amt = tax_value;
          detail.sgst_amt = 0;
          detail.cgst_amt = 0;
        }

      },
      checkIGST(){
        if (this.$props.state == 0 || (this.$props.state == store.state.user.state_cd)) {
          this.$data.isIntegrated = true;
          this.$data.isState =  false;
        }else{
          this.$data.isIntegrated = false;
          this.$data.isState =  true;
        }
      },
      rowValueEqualizer(rowIndex, colIndex) {
        const self = this;
        let tax_value = 0;

        let detail = self.$data.detail;
        switch (colIndex) {
          case 1:
            self.items.forEach((item) => {
              if(item.id === detail.item.id){
                detail.item = item;
              }
          });

            // detail.item = self.$data.items.get(detail.item.id);
            detail.gst_rate = parseFloat(detail.item.group.gstrate);
            break;
          case 2: //GST Rate
            detail.gst_rate = parseFloat(detail.item.group.gstrate);
            // console.log(detail.gst_rate);
            self.calculate_gst(detail);
            break;
          case 3://Product Rate
            detail.taxable_amt = (detail.qty * detail.rate) - detail.discount;
            detail.taxable_amt = numeral(detail.taxable_amt).format('0.00');
            self.calculate_gst(detail);
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
            break;
          case 4://Product Qty

            detail.taxable_amt = (detail.qty * detail.rate) - detail.discount;
            detail.taxable_amt = numeral(detail.taxable_amt).format('0.00');
            self.calculate_gst(detail);
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
            break;
          case 5: //Discount
            detail.taxable_amt = (detail.qty * detail.rate) - detail.discount;
            detail.taxable_amt = numeral(detail.taxable_amt).format('0.00');
            self.calculate_gst(detail);
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
            break;
          case 6://Taxable value
            detail.rate = (parseFloat(detail.taxable_amt) + parseFloat(detail.discount)) / parseFloat(detail.qty);
            self.calculate_gst(detail);
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');

            break;
          case 7:
          case 8:
          case 9:
            detail.tax_amt = numeral(parseFloat(detail.igst_amt) + parseFloat(detail.cgst_amt) + parseFloat(detail.sgst_amt)).format('0.00');
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
            break;
        }
        self.$emit('onrow_change',rowIndex, colIndex);
      },
    }
  }
</script>

<style scoped>

</style>
